class Accordion {
  constructor (el) {
    this.el = el
    const items = this.el.querySelectorAll('.accordion__item')
    items.forEach(item => {
      const button = item.querySelector('.accordion__button')
      button.addEventListener('click', () => {
        this.toggle(item)
      })
    })
  }
  toggle(item) {
    item.querySelector('.accordion__details').classList.toggle('hidden')
    item.querySelector('.accordion__chevron').classList.toggle('rotate')
  }
}

export const initAccordion = () => {
  return Array.from(document.querySelectorAll('.accordion')).map(accordion => {
    return new Accordion(accordion)
  })
}
