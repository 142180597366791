export const fromBacklink = () => {
  const overlay = document.querySelector('.exit-overlay');
  const body = document.querySelector('body');

  // if the user got to the page by using a back (or forward) button, redirect as in exit


  if (window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_BACK_FORWARD) {
    window.location.href = "https://www.wetteronline.de/"
  } else {
    body.style.opacity = '1';
  }
}
