import axios from "axios"

export const toggleForm = () => {
  const button = document.querySelector('#toggleContact')
  const form = document.querySelector('#form')

  button.addEventListener('click', () => {
    form.classList.toggle('hidden')
  })
}

const submitForm = (data) => {
  axios.get('/test', {params: data}).then((response) => {
    console.log(response)
  })
}

const validateContactForm = () => {
  const submit = document.querySelector('.contact__submit')
  const success = document.querySelector('.contact__success')

  const select = document.querySelector('.contact__form-select')
  const mail = document.querySelector('.contact__form-mail')
  const message = document.querySelector('.contact__form-message')

  const mailWrapper = document.querySelector('#contact-mail')
  const mailError = document.querySelector('#contact-mail-error')
  const messageWrapper = document.querySelector('#contact-message')
  const messageError = document.querySelector('#contact-message-error')

  // check values
  if (mail.value !== '' && !RegExp(/\S+@\S+\.\S+/).test(mail.value)) {
    mailWrapper.classList.add('error')
    mailError.classList.remove('hidden')
  } else if (message.value === '') {
    messageWrapper.classList.add('error')
    messageError.classList.remove('hidden')
  } else {
    // remove errors
    messageWrapper.classList.remove('error')
    messageError.classList.add('hidden')
    mailWrapper.classList.remove('error')
    mailError.classList.add('hidden')

    // change button
    submit.classList.add('hidden')
    success.classList.remove('hidden')
    success.classList.add('contact__success-show')
    // reset button
    setTimeout(() => {
      submit.classList.remove('hidden')
      success.classList.add('hidden')
      success.classList.remove('contact__success-show')
      mail.value = ''
      message.value = ''
      select.selectedIndex = 0

    }, 2500)
    const data = {
      subject: subject.value,
      mail: mail.value,
      message: message.value
    }
    return data
  }
}

export const toggleSubmit = () => {
  const submitButton = document.querySelector('#contact__submit-button')
  submitButton.addEventListener('click', (e) => {
    e.preventDefault()
    e.stopPropagation()
    let data = validateContactForm()
    submitForm(data)
  })
}
