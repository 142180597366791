export const stickySections = () => {
  const stickyWrapper = document.getElementById('stickyWrapper')
  const stickyInform = document.getElementById('stickyInform')
  const stickyActive = document.getElementById('stickyActive')
  const stickyNetwork = document.getElementById('stickyNetwork')

  window.addEventListener('scroll', () => {
    const wrapper = stickyWrapper.getBoundingClientRect()
    const inform = stickyInform.getBoundingClientRect()
    const active = stickyActive.getBoundingClientRect()
    const network = stickyNetwork.getBoundingClientRect()


    if (wrapper.top >= 0 && wrapper.bottom <= window.innerHeight) {
      // stickyWrapper.style.height = wrapper.height;
      console.log('Bottom of sticky wrapper')
      // stickyWrapper.style.position = 'relative';
      // stickyNetwork.style.position = 'absolute';
      // stickyNetwork.style.bottom = window.innerHeight - network.height;
      // make wrapper (or single items..? sticky... or position absolute to the bottom a hidden div that stretches across window height..?)
    }
    // if (inform.top >=0 && inform.bottom <= ((window.innerHeight - active.height - network.height))) {
    //   // stickyInform.style.height =

    //   // continually increasingly overwrite inform.height
    //   // decrease when scrolling in other direction
    // }
    // if (active.top >=0 && active.bottom <= ((window.innerHeight - network.height))) {
    //   // continually increasingly overwrite active.height
    //   // decrease when scrolling in other direction
    // }
    // if (network.top >=0 && network.bottom <= (window.innerHeight)) {
    //   // continually increasingly overwrite network.height
    //   // decrease when scrolling in other direction
    // }
  })
}


