export const scrollHeader = () => {
  const header = document.querySelector('.header')
  let initialPosition = window.pageYOffset;

  window.addEventListener('scroll', () => {
    const currentPosition = window.pageYOffset;

    if (window.scrollY >= 0) {
      if (initialPosition < currentPosition) {
        header.classList.add('hidden-header')
      } else {
        header.classList.remove('hidden-header')
      }
      initialPosition = currentPosition;
  }})
}
