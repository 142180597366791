export const toggleOverlay = () => {
  const buttons = Array.from(document.querySelectorAll('.toggle-overlay'))
  const overlay = document.querySelector('#overlay')

  buttons.forEach(button => {
    button.addEventListener('click', () => {
      overlay.classList.toggle('hidden')
    })
  })
}
