import "./main.scss";
import { exitWebsite } from "./exit-website";
import { fromBacklink } from "./from-backlink";
import { initAccordion } from "./accordion";
import { initBezierify } from "./bezierify";
import { initGoalsParallax, initZoomParallax } from "./parallax";
import { initSlider } from "./slider";
import { mobileOrder } from "./mobile-order";
import { scrollHeader } from "./header";
import { stickySections } from "./sticky-sections";
import { toggleOverlay } from "./overlay";
import { toggleForm, toggleSubmit } from "./contact-form";

document.addEventListener("DOMContentLoaded", () => {
  exitWebsite();
  fromBacklink();
  initAccordion();
  initBezierify();
  initGoalsParallax();
  initZoomParallax();
  mobileOrder();
  scrollHeader();
  stickySections();
  toggleForm();
  toggleOverlay();
  toggleSubmit();
  initSlider();
});
