const getNum = () => {
  let num = [1, 2, 3, 4][Math.floor(Math.random() * 4)];
  return num;
};

const emify = (str, final, num) => {
  let beforeEm; // letters before the <em> tag
  let em; // actual wrapped letter
  let afterEm; // X letters after the <em> tag
  // --> will be knitted together and pushed to final string array
  let rest; // rest of sentence to return and start the process over with

  // if first letter is empty, take the second one and add in a space before when pushing
  if (str[0] === " ") {
    beforeEm = num > 0 ? str.substring(0, num + 1) : "";
    em = str.substring(num + 1, num + 2);
    afterEm = str.substring(num + 2, num + 12);
    rest = str.substring(12);
    final.push(` <em>${em}</em>${afterEm}`);
  } else {
    beforeEm = num > 0 ? str.substring(0, num) : "";
    em = str.substring(num, num + 1);
    afterEm = str.substring(num + 1, num + 11);
    final.push(`${beforeEm}<em>${em}</em>${afterEm}`);
    rest = str.substring(num + 11);
  }
  return rest;
};

const bezierify = (string) => {
  let finalString = [];
  // get random number between 1 and 9 as index
  let num = getNum();
  // wrap a starting letter in <em> tags
  let initialString = emify(string, finalString, num);
  // pass on remaining sentence part
  let currentString = initialString;

  // repeat wrapping every 7th letter till sentence is over
  while (currentString.length > 0) {
    currentString = emify(currentString, finalString, 0);
  }

  // join strings of array to get the final string with <em> tags
  return finalString.join("");
};

export const initBezierify = () => {
  const headlines = Array.from(document.querySelectorAll("h1, h2, .headline"));
  headlines.map((headline) => {
    headline.innerHTML = bezierify(headline.innerHTML);
  });
};
