export const initSlider = () => {
  // initialize swiper
  const swiper = new Swiper(".swiper", {
    // Optional parameters
    loop: true,
    // Pagination
    pagination: {
      el: ".swiper-pagination",
    },
    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    spaceBetween: 20,
  });
  const statements = Array.from(swiper)[0];
  getRandomBubble(statements);
  return swiper;
};

const getSrc = () => {
  let number = [1, 2, 3, 4, 5, 6][Math.floor(Math.random() * 6)];
  let selected = `../../assets/images/statements_bubble_0${number}.svg`;
  return selected;
};

const getRandomBubble = (swiper) => {
  swiper.on("slideChange", () => {
    const bubbleSvg = document.querySelector(".bubble-img");
    bubbleSvg.classList.toggle("scale-down");
    setTimeout(() => {
      bubbleSvg.src = getSrc();
      bubbleSvg.classList.toggle("scale-down");
    }, 200);
  });
};
