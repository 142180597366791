// moves fore- and background of images in goals section at different speeds
export const initGoalsParallax = () => {
  const options = {
    treshold: 0.5,
  };
  let scrollPosition;
  let wrapperObserver = new IntersectionObserver((entries, options) => {
    entries.forEach((entry) => {
      let children = Array.from(entry.target.children);
      let foreGroundImage = Array.from(children[1].children)[0];

      if (entry.intersectionRatio > 0) {
        window.addEventListener("scroll", () => {
          scrollPosition = window.scrollY;
          foreGroundImage.style.webkitTransform = `translateY(${
            scrollPosition * 0.06
          }px)`;
          foreGroundImage.style.transform = `translateY(${
            scrollPosition * 0.06
          }px)`;
        });
      } else {
        foreGroundImage.style.transform = `translateY(0)`;
        foreGroundImage.style.webkitTransform = `translateY(0)`;
      }
    });
  });

  const goalsImages = Array.from(document.querySelectorAll(".stackImages"));
  goalsImages.map((goalImage) => {
    wrapperObserver.observe(goalImage);
  });
};

// Scales up all images within their clipped div coming from Kirby
export const initZoomParallax = () => {
  const options = {
    treshold: 0.5,
  };
  const factor = 12000;
  let wrapperObserver = new IntersectionObserver((entries, options) => {
    entries.forEach((entry) => {
      let initialPosition = window.scrollY;
      let currentPosition = window.scrollY;
      let scrolledPixels;
      let image = entry.target.children[0];

      if (entry.intersectionRatio > 0) {
        window.addEventListener("scroll", () => {
          currentPosition = window.scrollY;
          scrolledPixels = currentPosition - initialPosition;
          image.style.transform = `scale(${
            1 +
            (scrolledPixels > 1 ? scrolledPixels : scrolledPixels * -1) / factor
          })`;
          image.style.webkitTransform = `scale(${
            1 +
            (scrolledPixels > 1 ? scrolledPixels : scrolledPixels * -1) / factor
          })`;
        });
      } else {
        image.style.transform = `scale(1)`;
        image.style.webkitTransform = `scale(1)`;
      }
    });
  });

  const zoomImages = Array.from(document.querySelectorAll(".parallax"));
  zoomImages.map((zoomImage) => {
    wrapperObserver.observe(zoomImage);
  });
};
