export const exitWebsite = () => {
  const button = document.querySelector(".button__exit");
  const overlay = document.querySelector('.exit-overlay');

  button.addEventListener('click', () => {
    // reveal white overlay
    overlay.classList.add('hidden')
    // redirect to desired page
    window.location.href = "https://www.wetteronline.de/"
  })
};

