export const mobileOrder = () => {
  const cols = Array.from(document.querySelectorAll(".mobile-order"));

  // cols.map((col) => {
  //   if (col.firstElementChild.className === ("image" || "video")) {
  //     console.log("COL", col);
  //     col.classList.add("order-1");
  //   } else {
  //     col.classList.add("order-2");
  //   }
  // });
};
